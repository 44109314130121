import React from 'react'
import '../css/common.css'
import '../css/roadmap.css'

export default function Roadmap() {
  return (
    <div className='full-screen-holder'>
      <h1 className='title centered' id='roadmap'>Roadmap</h1>
      <div className='roadmap-holder'>
        <RoadmapElement title="The Mint" desc={[
          `Minting will begin for whitelist users only. After 24 hours, Minting will be open to the public!`,
          `15% of each mint is shared to all Apes, the more Apes you hold and the earlier you mint, the more rewards you receive!`,
          'For every 25% completion of the Mint, we will giveaway FLR & 1/1 NFT prizes!'
        ]}></RoadmapElement>
        <RoadmapElement title="What are the benefits?" desc={[
          `A 10% royalty fee is placed on all sales, 9% of this fee is distributed to all holders of our Apes, while 1% is given to addresses that minted!`,
          `Holders also receive 10% of the revenue generated by our FTSO, DEX and NFT Marketplace, as well as a portion generated by NFT deployments using our Viri NFT deployer`
        ]}></RoadmapElement>
        <RoadmapElement title="Mutate yo' Ape" desc={[
          `Following the Ape mint, Mutation Serums will be airdropped at a 5:1 ratio, Users can choose their own Ape to mutate!`,`Choose wisely, as once the Serum has been consumed, it will be burned forever!`,
        ]}></RoadmapElement>
        <RoadmapElement title="Connect Everything" desc={[
          `We plan to deploy our dApps to as many EVM chains as possible, releasing an NFT collection on each with all the same benefits as the Apes on their appropriate chains!` 
        ,`Apes & Mutants will receive income from the mint proceeds made from these collections!`]}></RoadmapElement>
      </div>
    </div>
  )
}

function RoadmapElement(props) {
  return (
    <div className='roadmap-element'>
      <div className='neon-border flex-column transparent-bg'>
        <h2 className='title centered'>{props.title}</h2>
        {props.desc.map((item, index) => {
          return (
            <p className='centered' key={index}>{item}</p>
          )
        })}
      </div>
    </div>
  )

}
